@import "../scss/breakpoints";
@import "../scss/variables";

//shared Component

.dk-button{

    color: $heaven;
    font-size: 1.5rem;
    padding-bottom: 8px;
    font-weight: 400;
    border-bottom: 2px solid;
    border-color: $heaven;

    .button-icon{

        margin-left: 8px;

    }
}

//Header

// Navbar Styles

.navbar{

    max-width: 1320px;
    margin: auto;
    position: sticky;

    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @include media(">xldesktop"){

        max-width: 1680px;

    }

    .nav-list{

        display: flex;
        gap: 2rem;

        .nav-items{

            font-size: 1.25rem;
            
            .nav-link{

                color: $heaven;

            }
        }

    }

}

// Main

// Hero Styles

.hero-section{

    max-width: 1280px;
    margin: auto;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 5rem;

    @include media(">xldesktop"){

        max-width: 1600px;

    }

    .hero-title{

        color: $heaven;
        font-size: 3rem;
        font-weight: 400;
    }

}

// Video Section

.video-section {

    max-width: 1280px;
    margin: auto;

    @include media(">xldesktop"){

        max-width: 1600px;

    }

    padding-bottom: 4rem;

    .dk-reels{

        background-color: $dove;
    }
}

// Latest Work Section

.latestwork-section {

    

    .section-title{

        max-width: 1280px;
        margin: auto;
        padding-bottom: 4rem;

        @include media(">xldesktop"){

            max-width: 1320px;

        }
        
        display: flex;
        align-items: center;
        gap: 4rem;

        padding-bottom: 4rem;

        .latestwork-title{

            width: 50%;
            color: $heaven;
            font-size: 3rem;
            font-weight: 400;
            text-align: left;

        }

        .latestwork-subtitle{

            width: 50%;
            color: $heaven;
            font-size: 1.5rem;

        }
    }

    // latest project section


    .latest-project-section{

        @include media(">xldesktop"){
            max-width: 1600px;
            margin: auto;
        }
    
        padding-bottom: 6rem;
    
        .project-wrapper{
    
            width: 100%;
    
            .project-card-left{
    
                margin: 0;
                display: flex;
                gap: 6rem;
                padding-bottom: 8rem; 
    
                
                .image-wrapper{

                    width: calc((100vw - 152px) / 2);
                    overflow: hidden;


                    .project-pic{
    
                        width: inherit;
                        object-fit: cover;
                        transition: transform 1.2s ease;
    
                    }

                    .project-pic:hover{

                        transform: scale(1.05);
                        transition: 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                    }


                }
    
                .project-data{

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding-right: 4rem;
                    width: 50%;
                    
                    .project-name{
    
                        color: $heaven;
                        font-size: 2rem;
                        font-weight: 400;
    
                    }
                    
                    .project-desc{
    
                        color: $ash;
                        font-size: 1.5rem;
                        font-weight: 400;
                        margin-bottom: 2rem;
    
                    }
    
                    .project-button{
    
                        color: $heaven;
                        font-size: 1.5rem;
                        padding-bottom: 8px;
                        font-weight: 400;
                        border-bottom: 2px solid;
                        border-color: $heaven;
    
                        .button-icon{
    
                            margin-left: 8px;
    
                        }
                    }
                }
            }
    
            .project-card-right{
    
                margin: 0;
                display: flex;
                gap: 6rem;
                flex-direction: row-reverse;
                
                .image-wrapper{

                    width: calc((100vw - 152px) / 2);
                    overflow: hidden;

                }
                .project-pic{
    
                    width: inherit;
                    object-fit: cover;
                    transition: transform 1.2s ease;
                }

                .project-pic:hover{

                    transform: scale(1.05);
                    transition: 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                }

             
    
                .project-data{
    
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding-left: 4rem;
                    
                    .project-name{
    
                        color: $heaven;
                        font-size: 2rem;
                        font-weight: 400;
    
                    }
                    
                    .project-desc{
    
                        color: $ash;
                        font-size: 1.5rem;
                        font-weight: 400;
                        margin-bottom: 2rem;
    
                    }
    
                    .project-button{
    
                        color: $heaven;
                        font-size: 1.5rem;
                        padding-bottom: 8px;
                        font-weight: 400;
                        border-bottom: 2px solid;
                        border-color: $heaven;
    
                        .button-icon{
    
                            margin-left: 8px;
    
                        }
                    }
                }
            }
        }
    
    }

    .latestwork-cta{

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 6rem;
        padding-bottom: 6rem;

        .latestwork-cta-title{

            text-align: center;
            color: $heaven;
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 2rem;

        }
        
    }
    

}

.brief-about-section{

    .image-about{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 6rem;
        padding-top: 4rem;
        padding-bottom: 4rem;

        .about-image1{

            width: calc(((((100vw - (2 * 152px)) - (76px * 11)) / 12 * 5) + (76px * 5) - 152px) + 152px);
            height: calc((((((100vw - (2 * 152px)) - (76px * 11)) / 12 * 5) + (76px * 5) - 152px) + 152px) / (681/911));
        }

        .about-image2{

            width: calc((((100vw - (2 * 152px)) - (76px * 11)) / 12 * 7) + (76px * 6));
            height: calc(((((100vw - (2 * 152px)) - (76px * 11)) / 12 * 7) + (76px * 6)) / (16/10));

        }

    }
    
    .brief-about-us{

        
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        
        padding-top: 4rem;
        padding-bottom: 6rem;

        .about-text{

            width: calc((((100vw - (2 * 152px)) - (76px * 11)) / 12 * 7) + (76px * 6));
            padding-right: 4rem;

            .brief-about-us-text{
            
                font-size: 2rem;
                font-weight: 400;
                line-height: 1.5;
                color: $heaven;

            }

        }
    }

}

.contactus-section{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 6rem;
    padding-top: 6rem;
    
    .contactus-text{

        text-align: center;
        font-size: 3rem;
        font-weight: 400;
        line-height: 1.5;
        color: $heaven;

    }

}

.footer-section{ 
    
    max-width: 1320px;
    margin: auto;
    padding-top: 6rem;
    padding-bottom: 6rem;

    display: flex;
    flex-direction: row;
    gap: 4rem;

    @include media(">xldesktop"){

        max-width: 1680px;

    }

    .footer-column-1{

        width: 35%;
        
        .text-footer{

            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $ash;

        }
    }

    .footer-column-2{

        width: 21.6666666667%;
        
        .heading-column{

            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.5;
            color: $heaven;
            margin-top: 0;

        }

        .menu-footer-column{

            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0;

            .menu-list{
                
                text-decoration: none;
                list-style: none;
                color: $ash;
            }

        }
    }
    

}