@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');


// font family

$font-family-base: 'Work Sans', sans-serif;

//color

$void: #151515;
$dove: #949494;
$ash: #A6A6A6;
$heaven: #FFFFFF;