@import "./variables";

body {
    
    overflow-x: hidden !important;
    margin: 0;
    background-color: $void;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}


h1, h2, h3, h4, h5, h6, p , a , li{

    line-height: 1.5;
    font-family: $font-family-base
}

li{

    list-style: none;
    text-decoration: none;

}

a {
    text-decoration: none !important;
}
